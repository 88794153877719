import React, { Suspense, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { ErrorMessage } from "./components/ErrorHandler/ErrorMessage";
import { useAppDispatch } from "./hooks/redux";

import "./App.css";
import { setBackendUrl } from "./store/devReducer";
import { lightTheme } from "./AppTheme";
import { AuthenicatedApp } from "./components/MainApp/AuthenticatedApp";
import { Loading } from "./components/Loading/Loading";
import ScrollToTop from './components/ReactRouter/ScrollToTop';

const isReporterApp = process.env.REACT_APP_BUILD_TARGET === "reporter";

const apiUrl = 
  process.env.REACT_APP_API_URL ?? "https://pulseproxy.azurewebsites.net";

const ReporterApp = React.lazy(
  () => import("./components/ReporterApp/ReporterApp")
);

const theme = lightTheme;

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const overrideApiUrl = new URL(window.location.href).searchParams.get(
      "api"
    );
    dispatch(setBackendUrl(overrideApiUrl ?? apiUrl));
  });

  const getMainComponent = (showReporterApp: boolean) => {
    if (showReporterApp) {
      return <ReporterApp />;
    } else {
      return <AuthenicatedApp />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter basename={window.location.pathname}>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <div className="App" aria-live="polite">{getMainComponent(isReporterApp)}</div>
          <ErrorMessage />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
