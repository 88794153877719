import {
  BrowserCacheLocation,
  Configuration,
  PopupRequest,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { CustomerDTO } from "../../api/models/CustomerDTO";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { logIn, logSignedInCustomer, setSignedInCustomerId, setToken } from "../../store/authReducer";
import {
  fetchCustomerBrandning,
  setCustomerId,
} from "../../store/brandingReducer";
import { RootState, store } from "../../store/store";
import { getLanguageCode } from "../LanguageSwitcher/LanguageSwitcher";
import { Loading } from "../Loading/Loading";
import { ChoseCustomer } from "../LogIn/ChoseCustomer";
import LogIn from "../LogIn/LogIn";
import MainApp from "./MainApp";

const loginRequest: PopupRequest = {
  scopes: ["openid", "email"],
};

export const AuthenicatedApp = () => {
  const msalConfig: Configuration = {
    auth: {
      clientId: "783d7a21-4bee-426b-9412-56534a11da0e",
      authority: "https://login.microsoftonline.com/common",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.MemoryStorage, // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };

  const mapToCustomerDTO = (customerId: string, customerName: string) : CustomerDTO => {
    let customer: CustomerDTO = {
      id: customerId,
      name: customerName
    };
    return customer;
  }
  
  let location = useLocation();

  const msalInstance = new PublicClientApplication(msalConfig);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  function onLogin(e: SyntheticEvent) {
    e.preventDefault();
    msalInstance
      .loginPopup(loginRequest)
      .then((response) => dispatch(setToken(response.idToken)))
      .then(() => dispatch(logIn()));
  }

  const authStatus = useAppSelector((state) => state.auth.status);
  const brandingStatus = useAppSelector((state) => state.branding.status);
  const defaultLanguage = useAppSelector((state) => state.branding.branding?.defaultLanguage);
  let isDefaultLanugageLoaded = useRef(false);
  const loading = authStatus === "LOGGING_IN" || brandingStatus === "LOADING_BRANDING";
  const loggedIn = authStatus === "LOGGED_IN";
  const brandingLoaded = brandingStatus === "LOADED"; 
  const userCustomer = useAppSelector((state) => state.auth.signedInCustomerId);

  const user = useAppSelector((state) => state.auth.user);
  const listOfCustomers = useAppSelector((state) => state.auth.user?.customersAndRoles?.map(x => mapToCustomerDTO(x.customerId!, x.customerName!)));
  const loggingInCheckCustomer = authStatus === "LOGGING_IN_CHECK_CUSTOMER";
  const userGotMultipleCustomers = user != null && user.customersAndRoles != null && user.customersAndRoles.length >= 2;
  
  const backendUrl = useAppSelector((state) => state.dev.backendUrl);

  useEffect(() => {
    if (loggingInCheckCustomer) {
        if (user != null && user.customersAndRoles != null && !userGotMultipleCustomers) {
          dispatch(setSignedInCustomerId(user.customersAndRoles[0].customerId!));
          dispatch(logSignedInCustomer(user.customersAndRoles[0].customerId!));
        }
      }
  }, [loggingInCheckCustomer, user, userGotMultipleCustomers, dispatch]);

  useEffect(() => {
    if (loggedIn && userCustomer != null) {
      dispatch(setCustomerId(userCustomer));
      dispatch(fetchCustomerBrandning());
    }
  }, [loggedIn, userCustomer, dispatch]);

  useEffect(() => {
    if(brandingStatus === "LOADED" && !isDefaultLanugageLoaded.current && defaultLanguage !== undefined){
      let defaultLanguageCode = getLanguageCode(defaultLanguage);
      i18n.changeLanguage(defaultLanguageCode);
      isDefaultLanugageLoaded.current = true;
    }
  }, [i18n, defaultLanguage, brandingStatus]);

  // Specific useEffect hook for non Microsoft-based SSO.
  useEffect(() => {
    // On entry URL for specific organisation
    const currentPath = window.location.pathname;
    switch(currentPath) {
      case "/bolagsverket":
        const organisation = "bolagsverket"
        
        window.location.href = `${backendUrl}/saml/login?org=${organisation}&returnurl=${window.location.origin}`
        break;
      case "/":
        // On return URL
        const idToken = new URLSearchParams(location.search).get("token");
        if(!idToken) break;
        
        dispatch(setToken(idToken))
        dispatch(logIn())
        break;
    }
  }, [location])

  return (
    <MsalProvider instance={msalInstance}>
      {loading ? <Loading /> : null}
      {loggedIn && brandingLoaded ? <MainApp /> : loggingInCheckCustomer && userGotMultipleCustomers ? <ChoseCustomer customers={listOfCustomers!} /> : <LogIn onLogin={onLogin} />}
    </MsalProvider>
  );
};
